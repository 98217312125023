import { gql } from '@apollo/client';

const GET_ALL_ATTACHMENTS_BY_ATTACHMENT_TYPE_ID = gql`
    query GetAllAttachmentsByAttachmentTypeId($Id: Int!) {
        getAllAttachmentsByAttachmentTypeId(Id: $Id) {
            code
            success
            message
            results {
                Id
                FileName
                FileUrl
                AttachmentType
                AttachmentType_Fk
                CreatedBy
                CreatedDate
            }
        }
    }
`;

export default GET_ALL_ATTACHMENTS_BY_ATTACHMENT_TYPE_ID;
