import { gql } from '@apollo/client';

export const DELETE_ATTACHMENT_BY_ID = gql`
    mutation DeleteAttachment($id: Int!) {
        deleteAttachment(id: $id) {
            code
            success
            message
        }
    }`;

export default DELETE_ATTACHMENT_BY_ID;
