import { gql } from '@apollo/client';

const UPLOAD_PURCHASE_ORDER_ATTACHMENTS = gql`
    mutation UploadPurchaseOrderAttachments($attachment: JSONObject!, $otherDetails: JSONObject! ) {
        uploadPurchaseOrderAttachments(attachment: $attachment, otherDetails: $otherDetails) {
            code
            success
            message
            url
        }
    }
`;

export default UPLOAD_PURCHASE_ORDER_ATTACHMENTS;
