import React, { useState, useCallback, useEffect } from 'react';
import {
  Button, Modal, Tabs,
} from 'antd';
import { useLazyQuery } from '@apollo/client';
import {
  EyeOutlined, PlusOutlined,
} from '@ant-design/icons';
import { RefreshSharp } from '@mui/icons-material';
import { get, isNaN, set } from 'lodash';
import { useLocation } from 'react-router-dom';

import GET_ALL_PURCHASE_ORDERS_FOR_TABLE_DATA from '@graphql/TH_getAllPurchaseOrdersForTableData';
import GET_PURCHASE_ORDER_BY_ID from '@graphql/TH_getPurchaseOrderById';

import { dateToString } from '@utils/helpers/date';
import useList from '@hooks/useList';

import { ContentStyled } from '@components/Styles/Global';
import { TablePaginationStyled, TableStyled } from '@components/Styles/TableStyles';
import { SpaceActionButton } from '@components/Styles/ButtonStyles';
import ButtonTooltip from '@components/Button/ButtonTooltip';
import Search from 'antd/es/input/Search';
import PurchaseOrderV1ViewUpdate from './PurchaseOrderv1_ViewUpdate';
import PurchaseOrderV1 from './PurchaseOrderv1';
import PurchaseOrderPartsV1 from './PurchaseOrderPartsV1';
import PurchaseOrderPartsReport from './PurchaseOrderPartsReport';
import InboundOutboundJobs from './InboundOutboundJobs';
import PurchaseOrderAttachment from './PurchaseOrderAttachment';

function PurchaseOrderLabs() {
  const [currentTab, setCurrentTab] = useState('po');
  const [visible, setPOModalVisible] = useState(false);
  const [addPOModalVisible, setAddPOModalVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [action, setAction] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [poDetails, setPoDetails] = useState({});
  const location = useLocation();

  const {
    data: purchaseOrderResults, count: total, loading: getAllPOLoading, refetch: getAllPORefetch, filters,
  } = useList({ query: GET_ALL_PURCHASE_ORDERS_FOR_TABLE_DATA });
  const {
    pageSize, currentPage, setCurrentPage, setSearchQuery,
  } = filters;

  const [getPurchaseOrderById, { loading: getByIdLoading }] = useLazyQuery(GET_PURCHASE_ORDER_BY_ID, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const poDetails = get(data, 'result', {});
      setPoDetails(poDetails);
    },
  });

  const handleChangeTab = useCallback((key) => {
    setCurrentTab(key);
  }, []);

  const showAddPOModal = () => {
    setAddPOModalVisible(true);
  };

  const handleRefresh = useCallback(() => {
    getAllPORefetch();
  }, []);

  const handleCancelAddPoModal = () => {
    setAddPOModalVisible(false);
  };

  const handleCancel = () => {
    setPOModalVisible(false);
    setSelectedRow(null);
    setPoDetails({});
  };

  const handleRowClick = (record) => {
    setSelectedRow(record);
    setPOModalVisible(true);
  };

  const handleSuccessAdd = useCallback(() => {
    handleCancelAddPoModal();
    getAllPORefetch();
  }, []);

  const handleOnSuccessUpdate = useCallback(() => {
    getAllPORefetch();
  }, []);

  const handleOnSave = useCallback(() => {
    if (action) {
      action();
    }
  }, [action]);

  const handleOnSubmitAction = useCallback((action) => {
    setAction(action);
  }, []);

  const handleOnLoading = useCallback((loading) => {
    setIsLoading(loading);
  }, []);

  const handleChangePage = useCallback((pageNumber) => {
    setCurrentPage(pageNumber);
  }, []);

  const handleSearch = useCallback((query) => {
    setSearchQuery(query);
    setCurrentPage(1);
  }, []);

  const columns = [
    {
      title: 'P.O. No.',
      dataIndex: 'PONumber',
      key: 'PONumber',
      sorter: (a, b) => a.PONumber.length - b.PONumber.length,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <Button
          type="link"
          icon={<EyeOutlined />}
          onClick={() => handleRowClick(record)}
        >
          {text}
        </Button>
      ),
    },
    {
      title: 'P.O. Date',
      dataIndex: 'PODate',
      key: 'PODate',
      sorter: (a, b) => a.PODate.length - b.PODate.length,
      sortDirections: ['descend', 'ascend'],
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {text}
        </span>
      ),
    },
    {
      title: 'P.O. Status',
      dataIndex: 'POStatus',
      key: 'POStatus',
      sorter: (a, b) => a.POStatus.length - b.POStatus.length,
      sortDirections: ['descend', 'ascend'],
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {text}
        </span>
      ),
    },
    {
      title: 'Mode of Transport',
      dataIndex: 'ModeOfTransport',
      key: 'ModeOfTransport',
      sorter: (a, b) => (a.ModeOfTransport || '') - (b.ModeOfTransport || ''),
      sortDirections: ['descend', 'ascend'],
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {text?.Code}
        </span>
      ),
    },
    {
      title: 'Customer',
      dataIndex: 'CustomerParty',
      key: 'CustomerParty',
      sorter: (a, b) => (a.CustomerParty || '') - (b.CustomerParty || ''),
      sortDirections: ['descend', 'ascend'],
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {text?.Name}
        </span>
      ),
    },
    {
      title: 'Supplier',
      dataIndex: 'SupplierParty',
      key: 'SupplierParty',
      sorter: (a, b) => (a.SupplierParty || '') - (b.SupplierParty || ''),
      sortDirections: ['descend', 'ascend'],
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {text?.Name}
        </span>
      ),
    },
    {
      title: 'Consignee',
      dataIndex: 'ConsigneeParty',
      key: 'ConsigneeParty',
      sorter: (a, b) => (a.ConsigneeParty || '') - (b.ConsigneeParty || ''),
      sortDirections: ['descend', 'ascend'],
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {text?.Name}
        </span>
      ),
    },
    {
      title: 'P.O. Type',
      dataIndex: 'POType',
      key: 'POType',
      sorter: (a, b) => a.POType.length - b.POType.length,
      sortDirections: ['descend', 'ascend'],
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {text}
        </span>
      ),
    },
    {
      title: 'Required Date',
      dataIndex: 'RequiredDate',
      key: 'RequiredDate',
      sorter: (a, b) => a.RequiredDate.length - b.RequiredDate.length,
      sortDirections: ['descend', 'ascend'],
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {dateToString(text)}
        </span>
      ),
    },
    {
      title: 'Created By',
      dataIndex: 'CreatedBy',
      key: 'CreatedBy',
      sorter: (a, b) => (a.CreatedBy || '').length - (b.CreatedBy || '').length,
      sortDirections: ['descend', 'ascend'],
      render: (text) => (
        <span style={{ userSelect: 'text' }}>
          {text}
        </span>
      ),
    },
  ];

  const tabActions = {
    onSubmitAction: handleOnSubmitAction,
    onLoading: handleOnLoading,
    onSuccess: handleOnSuccessUpdate,
    selectedRowId: selectedRow?.Id,
    poDetails,
    poDetailsLoading: getByIdLoading,
    currentTab,
  };

  useEffect(() => {
    if (selectedRow?.Id) {
      getPurchaseOrderById({ variables: { Id: selectedRow?.Id } });
    }
  }, [selectedRow?.Id]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const id = params.get('id');
    if (id) {
      const parsedId = parseInt(id, 10);
      if (!isNaN(parsedId)) {
        setSelectedRow({ Id: parsedId });
        setPOModalVisible(true);
      }
    }
  }, []);

  return (
    <ContentStyled>
      <SpaceActionButton>
        <Search
          placeholder="Search..."
          allowClear
          onSearch={handleSearch}
          style={{ width: 304 }}
        />
        <ButtonTooltip
          type="default"
          onClick={handleRefresh}
          icon={<RefreshSharp />}
          tooltipProps={{ title: 'Refresh' }}
        />
        <ButtonTooltip
          type="default"
          onClick={showAddPOModal}
          icon={<PlusOutlined />}
          tooltipProps={{ title: 'Add Purchase Order' }}
        />
      </SpaceActionButton>
      <TableStyled
        size="small"
        dataSource={purchaseOrderResults}
        columns={columns}
        rowClassName={(record) => (record?.ModeOfTransport?.Code === 'UGAIR' ? 'highlighted-row' : '')}
        pagination={false}
        loading={getAllPOLoading}
        scroll={{
          x: true,
        }}
      />
      <TablePaginationStyled current={currentPage} defaultCurrent={1} pageSize={pageSize} total={total} onChange={handleChangePage} showSizeChanger={false} />
      {visible
        && (
          <Modal
            title="Purchase Order Details"
            open={visible}
            onCancel={handleCancel}
            style={{ top: '54px' }}
            width="95%"
            styles={{ body: { height: 'calc(90vh - 108px)', overflow: 'auto' } }}
            maskClosable={false}
            keyboard={false}
            footer={[
              <div key={1} />,
              <Button key={2} type="primary" onClick={handleOnSave} disabled={isLoading} loading={isLoading}>
                Save
              </Button>,
            ]}
          >
            <Tabs
              defaultActiveKey="po"
              onChange={handleChangeTab}
              items={[
                {
                  key: 'po',
                  label: 'Purchase Order',
                  children: <PurchaseOrderV1ViewUpdate {...tabActions} />,
                },
                {
                  key: 'poPart',
                  label: 'Purchase Order - Parts',
                  children: <PurchaseOrderPartsV1 {...tabActions} />,
                },
                {
                  key: 'inOut',
                  label: 'Inbound/Outbound Jobs',
                  children: <InboundOutboundJobs {...tabActions} />,
                },
                // {
                //   key: 'print',
                //   label: 'Print Document',
                //   children: <PurchaseOrderPartsReport {...tabActions} />,
                // },
                {
                  key: 'attachments',
                  label: 'Attachments',
                  children: <PurchaseOrderAttachment {...tabActions} />,
                },
              ]}
            />
          </Modal>
        )}
      {addPOModalVisible
        && (
          <Modal
            title="Add New Purchase Order"
            open={addPOModalVisible}
            onCancel={handleCancelAddPoModal}
            style={{ top: '54px' }}
            width="95%"
            styles={{ body: { height: 'calc(90vh - 108px)', overflow: 'auto' } }}
            maskClosable={false}
            keyboard={false}
            footer={[
              <div key={1} />,
              <Button key={2} type="primary" onClick={handleOnSave} disabled={isLoading} loading={isLoading}>
                Create
              </Button>,
            ]}
          >
            <PurchaseOrderV1
              onSubmitAction={handleOnSubmitAction}
              getAllPORefetch={getAllPORefetch}
              onLoading={handleOnLoading}
              onSuccess={handleSuccessAdd}
            />
          </Modal>
        )}
    </ContentStyled>
  );
}

export default PurchaseOrderLabs;
