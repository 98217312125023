import React, {
  memo, useCallback, useEffect, useRef, useState,
} from 'react';
import {
  Button,
  Flex,
  Col, Tooltip, Row, Space,
  Upload, Image, Popconfirm,
} from 'antd';
import { Content } from 'antd/es/layout/layout';
import { UploadOutlined, DeleteOutlined, ExpandAltOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';

import useAuthStore from '@store/authStore';
import { getBase64 } from '@utils/helpers/image';

import UPLOAD_PURCHASE_ORDER_ATTACHMENTS from '@graphql/uploadPurchaseOrderAttachment';
import GET_ALL_ATTACHMENTS_BY_ATTACHMENT_TYPE_ID from '@graphql/TH_getAttachmentsByAttachmentTypeId';
import DELETE_ATTACHMENT_BY_ID from '@graphql/deleteAttachments';

import { TableStyled } from '@components/Styles/TableStyles';
import { showNotification } from '@utils/helpers/notifications';

function PurchaseOrderAttachment({
  selectedRowId,
  currentTab,
  poDetails,
  poDetailsLoading,
}) {
  const [user] = useAuthStore((state) => [state.user]);
  const [uploadPurchaseOrderAttachments] = useMutation(UPLOAD_PURCHASE_ORDER_ATTACHMENTS);

  const [getAttachments, { data: dataAttachments, loading: getAttachmentsLoading, refetch: refetchAttachments }] = useLazyQuery(GET_ALL_ATTACHMENTS_BY_ATTACHMENT_TYPE_ID, {
    variables: { Id: selectedRowId },
  });

  const [deleteAttachment] = useMutation(DELETE_ATTACHMENT_BY_ID);

  useEffect(() => {
    if (currentTab === 'attachments') {
      getAttachments();
    }
  }, [currentTab]);

  const columns = [
    {
      title: 'File Name',
      dataIndex: 'FileName',
      key: 'FileName',
      width: 200,
    },
    {
      title: 'Attachments',
      dataIndex: 'FileUrl',
      key: 'FileUrl',
      render: (_, attachmentRecord) => {
        const url = attachmentRecord?.FileUrl || '';
        const fileName = attachmentRecord?.FileName || '';

        return (
          <Tooltip title={fileName}>
            <Button icon={<ExpandAltOutlined />} href={url} target="_blank" rel="noopener noreferrer">
              {fileName}
            </Button>
          </Tooltip>
        );
      },
    },
    {
      title: '',
      key: 'actions',
      render: (_, attachmentRecord) => (
        <Space size="middle">
          <Popconfirm
            title="Delete Attachment"
            description="Are you sure to delete this attachment?"
            onConfirm={() => handleDeleteAttachment(attachmentRecord?.Id)}
            okText="Yes"
            cancelText="No"
          >
            <Button
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </Space>
      ),
      width: 80,
    },
  ];

  const handleUpload = useCallback((file) => {
    getBase64(file, (url) => {
      const data = url.split('base64,');
      const attachment = {
        filename: file.name,
        mimetype: file.type,
        encoding: 'base64',
        data: data[1],
      };
      uploadToAzureBlob(attachment);
    });
  }, []);

  const uploadToAzureBlob = (attachment) => {
    try {
      uploadPurchaseOrderAttachments({
        variables: {
          attachment,
          otherDetails: poDetails,
        },
      }).then((res) => {
        console.log('res', res);
        if (res?.data?.success) {
          showNotification('success', 'Success', res?.data?.message);
          refetchAttachments();
        }
      });
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleDeleteAttachment = (id) => {
    deleteAttachment({
      variables: {
        id,
      },
    }).then((res) => {
      console.log('res', res);
      if (res?.data?.success) {
        showNotification('success', 'Success', res?.data?.message);
        refetchAttachments();
      }
    });
  };

  return (
    <Content style={{ padding: 16 }}>
      <Flex justify="flex-end">
        <Upload
          // accept=".png, .jpg, .jpeg"
          maxCount={1}
          multiple={false}
          // onRemove={handleRemoveLogo}
          beforeUpload={handleUpload}
          showUploadList={false}
        >
          <Button
            icon={<UploadOutlined />}
          >
            Upload File
          </Button>
        </Upload>
      </Flex>
      <br />
      <Row gutter={24}>
        <Col xs={24}>
          <TableStyled
            rowKey="id"
            bordered
            columns={columns}
            dataSource={dataAttachments?.results ? dataAttachments.results : []}
            loading={getAttachmentsLoading}
            pagination
            scroll={{ x: true }}
          />
        </Col>
      </Row>
    </Content>
  );
}

export default memo(PurchaseOrderAttachment);
