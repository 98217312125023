import { gql } from '@apollo/client';

const UPLOAD_WAREHOUSE_IN_ATTACHMENTS = gql`
    mutation UploadWarehouseInAttachments($attachment: JSONObject!, $otherDetails: JSONObject! ) {
        uploadWarehouseInAttachments(attachment: $attachment, otherDetails: $otherDetails) {
            code
            success
            message
            url
        }
    }
`;

export default UPLOAD_WAREHOUSE_IN_ATTACHMENTS;
