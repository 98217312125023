import { gql } from '@apollo/client';

const UPLOAD_WAREHOUSE_OUT_ATTACHMENTS = gql`
    mutation UploadWarehouseOutAttachments($attachment: JSONObject!, $otherDetails: JSONObject! ) {
        uploadWarehouseOutAttachments(attachment: $attachment, otherDetails: $otherDetails) {
            code
            success
            message
            url
        }
    }
`;

export default UPLOAD_WAREHOUSE_OUT_ATTACHMENTS;
